nav {
  background-color: var(--bg-color);
  border: 2px solid var(--border-color);
  flex-direction: row;
  gap: 10px;
  padding: 10px;
  display: flex;
}

a {
  color: var(--action-color);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.Pcwi7a_active {
  color: #fff !important;
}

.bY0ouG_mainContainer {
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  margin-top: 10px;
  display: flex;
}

.bY0ouG_middleContainer {
  background-image: url("scroll_mid.0db70f0b.gif");
  background-repeat: repeat-y;
  background-size: 100% 164px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 1rem;
  display: flex;
  position: relative;
}

.bY0ouG_middleContainer:before {
  content: "";
  background-image: url("scroll_top.eb2ec38d.gif");
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  width: 103%;
  height: 50px;
  position: absolute;
  top: -11px;
  left: -1%;
}

.bY0ouG_middleContainer:after {
  content: "";
  background-image: url("scroll_bottom.ed84409d.gif");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  width: 103%;
  height: 50px;
  position: absolute;
  top: auto;
  bottom: -11px;
  left: -1%;
}

@media screen and (width >= 500px) {
  .bY0ouG_middleContainer {
    padding: 2rem 1rem;
  }
}

._3vGv9a_container {
  background-color: var(--bg-color);
  border: 2px solid var(--border-color);
  color: #f5f5f5;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  padding: 10px;
  font-size: 12px;
  display: flex;
}

._3vGv9a_container p {
  margin: 0;
}

._3vGv9a_container img {
  width: 50px;
}

._3vGv9a_center {
  align-items: center;
  gap: 3px;
  display: flex;
}

.ztywgG_container {
  gap: 10px;
  margin: 10px 0;
  display: flex;
}

.ztywgG_imgContainer, .ztywgG_textContainer {
  flex: 2;
}

.ztywgG_container a {
  color: var(--subheader-color);
  font-weight: bold;
}

.ztywgG_date {
  font-style: italic;
}

.jiMMYa_container {
  background-color: #000;
  background-image: url("rsbg.1515c323.jpg");
  background-position: top;
  background-repeat: repeat-y;
  background-size: auto;
  background-attachment: fixed;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  display: flex;
}

.jiMMYa_innerContainer {
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-bottom: 1rem;
  padding: 1rem 2rem;
  display: flex;
}

.jiMMYa_headerImg {
  width: 90%;
  max-width: 400px;
  margin-bottom: 1rem;
}

h1 {
  color: var(--header-color);
  text-shadow: 1px 1px 1px #000;
}

img {
  width: 100%;
}

.dcaSsW_featuredContainer {
  width: 100%;
  min-height: 325px;
  display: flex;
}

.dcaSsW_iframe {
  border: none;
  flex-grow: 1;
  margin: 0;
  padding: 0;
}

.dcaSsW_socialsContainer {
  gap: 1rem;
  display: flex;
}

.dcaSsW_socialsContainer a {
  color: var(--subheader-color);
  align-items: center;
  gap: 5px;
  display: flex;
}

.dcaSsW_socialsContainer img {
  width: 25px;
}

._5Iz5hq_header {
  flex-direction: column;
  align-items: center;
  display: flex;
}

._5Iz5hq_tableWrapper {
  max-width: 100%;
  overflow-x: auto;
}

table {
  border-collapse: separate;
  border-spacing: 20px 0;
}

th {
  text-align: left;
  font-weight: 600;
}

table a {
  color: #000;
  text-decoration: none;
}

table a img {
  width: 15px;
  margin-right: 5px;
}

table a:hover {
  text-decoration: underline;
}

@media screen and (width >= 600px) {
  ._5Iz5hq_tableContainer {
    padding: .5rem;
  }
}

.L1NTCG_container {
  width: 100%;
}

.L1NTCG_innerContainer {
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
  display: flex;
}

.L1NTCG_link {
  color: var(--subheader-color);
  font-weight: bold;
}

.L1NTCG_canvas {
  will-change: transform;
  border-radius: 8px;
  width: 100%;
  height: 0;
  padding-top: 75%;
  padding-bottom: 0;
  position: relative;
  overflow: hidden;
  box-shadow: 0 2px 8px #3f455129;
}

.L1NTCG_iframe {
  border: none;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
}

* {
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background-color: #000;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 8px 0;
}

p {
  font-size: 14px;
}

hr {
  background-color: var(--border-color);
  border: 0;
  width: 100%;
  height: 1px;
}

:root {
  --action-color: #90c040;
  --bg-color: black;
  --border-color: #382418;
  --header-color: #6d4211;
  --subheader-color: #7b4f17;
}
/*# sourceMappingURL=index.edace718.css.map */
