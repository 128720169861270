* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  background-color: black;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 8px 0;
}

p {
  font-size: 14px;
}

hr {
  width: 100%;
  height: 1px;
  border: 0;
  background-color: var(--border-color);
}

:root {
  --action-color: #90c040;
  --bg-color: black;
  --border-color: #382418;
  --header-color: #6d4211;
  --subheader-color: #7b4f17;
}
